import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useCollection = key =>
  useQuery({
    queryKey: key,
    staleTime: Infinity,
  }).data;

export const useSetCollection = key => {
  const queryClient = useQueryClient();
  return state => queryClient.setQueryData(key, state);
};
