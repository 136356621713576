import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="absolute -bottom-[177px] flex h-[157px] w-full flex-col items-center justify-center bg-default-violet px-0 py-[15px] md:-bottom-[157px] md:flex-row md:justify-between md:px-[27px] lg:px-[107px] xl:px-[184px] 2xl:px-[300px]">
      <h1 className="my-0 mb-[17px] w-full cursor-pointer text-center text-xl font-bold text-white md:mb-0 md:text-left md:text-3xl lg:inline-block lg:text-left">
        Help Me
      </h1>
      <div className="w-full text-right text-white">
        {/* <p className="text-center md:text-right">Customer Service :</p>
        <p className="text-center md:text-right">Address :</p> */}
        <p className="text-center md:text-right">ⓒ 2024 HelpME Company. All Rights Reserved.</p>
        <div className="mt-[10px] flex w-full justify-center space-x-[20px] md:justify-end">
          <a
            href="https://friendly-snowboard-012.notion.site/afe36aadd1e64b9ca253e0b9c574ca04?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold"
          >
            {t('signup.privacyPolicy')}
          </a>
          <a
            href="https://friendly-snowboard-012.notion.site/c0a42640f8cc4143b99caf7916a7bddc?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold"
          >
            {t('signup.termsOfUse')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
