import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="flex h-[70px] justify-between bg-default-violet pl-5 md:h-[100px] md:px-[32px] lg:justify-between lg:px-[84px] xl:px-[184px] 2xl:px-[300px]">
      <div onClick={() => navigate('/')} className="flex items-center">
        <h1 className="my-0 cursor-pointer text-xl font-bold  text-white md:text-3xl lg:inline-block lg:text-left ">
          Help Me
        </h1>
      </div>
      <Navigation />
    </header>
  );
};

export default React.memo(Header);
