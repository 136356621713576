import { useRole } from 'hooks/useRole';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ roles }) => {
  const { isAuthenticated } = useAuth();
  const role = useRole(['role']);

  if (!isAuthenticated) {
    // alert(t('login.alert'));
    return <Navigate to="/login" />;
  }

  if (roles && !roles.includes(role)) {
    if (role === 'counselor') {
      return <Navigate to="/admin" />;
    }
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const PublicRoute = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export { PrivateRoute, PublicRoute };
