import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCookie, setCookie } from 'utils/cookies';

export const useRole = key =>
  useQuery({
    queryKey: key,
    staleTime: Infinity,
    initialData: () => getCookie('role'),
  }).data;

export const useSetRole = key => {
  const queryClient = useQueryClient();
  return (state, options) => {
    queryClient.setQueryData(key, state);
    setCookie('role', state, options);
  };
};
