import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name, value, option) => {
  const defaultOptions = {
    path: '/',
    maxAge: 7 * 24 * 60 * 60,
  };

  return cookies.set(name, value, { ...defaultOptions, ...option });
};

export const getCookie = name => {
  return cookies.get(name);
};

export const removeCookie = (name, option) => {
  const defaultOptions = {
    path: '/',
  };

  return cookies.remove(name, { ...defaultOptions, ...option });
};
