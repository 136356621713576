import Banner from 'components/Banner';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import plus from '../assets/images/icon/plus.svg';
import councelor from '../assets/images/main/councelor.svg';
import interviewImg from '../assets/images/main/interview.svg';
import reviewImg from '../assets/images/main/review.svg';
import safety from '../assets/images/main/safety.svg';
import support from '../assets/images/main/support.svg';
import telephoneImg from '../assets/images/main/telephone.svg';
import textImg from '../assets/images/main/text.svg';

import ImageComponent from 'components/ImageComponent';
import line_center_lg from '../assets/images/main/line_center_lg.svg';
import line_center_md from '../assets/images/main/line_center_md.svg';
import line_left_lg from '../assets/images/main/line_left_lg.svg';
import line_left_md from '../assets/images/main/line_left_md.svg';

const MainSection2 = () => {
  const { t } = useTranslation();

  const [src, setSrc] = useState({
    left: line_left_md,
    center: line_center_md,
  });

  const updateSrc = useCallback(() => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      setSrc({
        left: line_left_md,
        center: line_center_md,
      });
    } else if (window.matchMedia('(min-width: 1024px)').matches) {
      setSrc({
        left: line_left_lg,
        center: line_center_lg,
      });
    }
  }, []);

  useEffect(() => {
    updateSrc();
    window.addEventListener('resize', updateSrc);
    return () => window.removeEventListener('resize', updateSrc);
  }, [updateSrc]);

  const mockHelpMethod = [
    {
      title: t('connectInfo.helps.phone'),
      src: telephoneImg,
    },
    {
      title: t('connectInfo.helps.message'),
      src: textImg,
    },
    {
      title: t('connectInfo.helps.offlineMeeting'),
      src: interviewImg,
    },
  ];

  return (
    <>
      <section className="mt-[74px] px-[25px] pb-[53px] md:px-[32px] md:pb-[93px] lg:px-[90px] lg:pb-[79px] lg:pt-[69px] xl:px-[170px] 2xl:px-[300px]">
        <h1 className="break-keep text-center text-[30px] font-bold md:text-xl lg:text-2xl">
          {t('main.provideAny')}
        </h1>
        <section className="mt-[75px] flex justify-between md:mt-[87px]">
          {mockHelpMethod.map(method => (
            <div key={method.title} className={`flex w-[33.3%] flex-col items-center md:w-[170px]`}>
              <div className="w-[86px] rounded-full border border-solid border-[#777777] p-5 md:w-[136px]">
                <ImageComponent path={method.src} alt={method.title} />
              </div>
              <p className="break-keep text-center font-semibold xl:mt-[23px]">{method.title}</p>
            </div>
          ))}
        </section>
        <section>
          <ul className="ml-0 mt-[12px] flex justify-center md:justify-between ">
            <li className="w-[72px] md:ml-[90px] md:w-[136px] lg:w-full">
              <ImageComponent path={src.left} alt="left line" />
            </li>
            <li className="flex h-[103px] w-[72px] justify-center md:h-auto md:w-[136px]">
              <ImageComponent path={src.center} alt="center line" />
            </li>
            <li className="mr-0 w-[72px] md:mr-[90px] md:w-[136px] lg:flex lg:w-full lg:justify-end">
              <ImageComponent path={src.left} alt="right line" style="-scale-x-100" />
            </li>
          </ul>
        </section>
        <section>
          <h2 className="mt-[30px] break-keep text-center text-xl font-bold leading-10 text-default-violet md:mt-[226]">
            {t('main.noMatter_01')}
          </h2>
          <p className="mt-[19px] break-keep text-center md:text-lg lg:mt-[29px] ">
            {t('main.noMatter_02')}
          </p>
        </section>
      </section>
    </>
  );
};

const MainSection3 = () => {
  const { t } = useTranslation();
  const mockService = [
    {
      title: t('main.safety.title'),
      content1: t('main.safety.explanation_01'),
      content2: t('main.safety.explanation_02'),
      src: safety,
    },
    {
      title: t('main.individual_support.title'),
      content1: t('main.individual_support.explanation_01'),
      content2: t('main.individual_support.explanation_02'),
      src: support,
    },
    {
      title: t('main.customized_service.title'),
      content1: t('main.customized_service.explanation_01'),
      content2: t('main.customized_service.explanation_02'),
      src: councelor,
    },
  ];

  return (
    <section className="bg-lighten-violet px-[20px] pb-[44px] pt-[66px] md:pb-[82px] md:pt-[71px] lg:px-[190px] xl:px-[170px] xl:pb-[119px] 2xl:px-[300px]">
      <h1 className="text-center text-2xl font-bold">{t('main.customized_service.title')}</h1>
      <p className="mt-[37px] break-keep text-center text-lg md:mt-[23px] lg:mt-[35px]">
        <span>{t('main.we_ensure')}</span>
      </p>
      <section className="mt-[40px] flex flex-col space-y-[18px] md:mt-[50px] lg:mt-[55px] xl:mt-[65px] xl:flex-row xl:space-x-[140px]">
        {mockService.map((service, idx) => (
          <ul
            key={service.title}
            className="relative flex flex-row rounded-[30px] border-2 border-solid border-default-violet bg-white px-[34px] py-[20px] lg:px-[20px] xl:min-h-[410px] xl:w-[345px] xl:flex-col xl:rounded-[50px] xl:px-0 xl:py-[51px]"
          >
            <li className="flex w-[100px] items-center md:min-h-[130px] md:w-[260px] md:justify-center lg:min-h-[146px] lg:items-end xl:m-auto xl:pl-[69px] 2xl:pl-0">
              <ImageComponent
                path={service.src}
                alt={`${service.title} image`}
                style="h-full w-[72px] md:w-auto lg:m-auto lg:h-auto"
              />
            </li>
            <li className="flex-1 xl:flex-grow-0">
              <ul>
                <li className="pl-[13px] text-left text-[25px] font-semibold md:pl-[5px] lg:pl-0 lg:text-xl xl:pt-[35px] xl:text-center">
                  <h2>{service.title}</h2>
                </li>
                <li className="my-[10px] xl:my-[20px] xl:px-[35px]">
                  <hr className="color-bordergray h-[2px]" />
                </li>
                <li
                  className={`list-inside list-disc break-keep py-1 lg:py-2 xl:pl-[20px] ${idx === 0 && '2xl:pl-[50px]'}`}
                >
                  {service.content1}
                </li>
                <li
                  className={`list-inside list-disc break-keep py-1 lg:py-2 xl:pl-[20px] ${idx === 0 && '2xl:pl-[50px]'}`}
                >
                  {service.content2}
                </li>
                {idx !== mockService.length - 1 && (
                  <li
                    className="absolute -bottom-[35px] left-1/2
                    z-10 -translate-x-1/2
                      transform xl:-right-[120px] xl:bottom-auto xl:left-auto xl:top-1/2 xl:-translate-y-1/2"
                  >
                    <ImageComponent path={plus} alt={`Plus Icon`} />
                  </li>
                )}
              </ul>
            </li>
          </ul>
        ))}
      </section>
    </section>
  );
};

const MainSection4 = () => {
  const { t } = useTranslation();
  return (
    <section className="mb-[30px] px-[46px] pt-[61px] md:px-[30px] md:pt-[81px] lg:px-0 lg:pt-[83px] xl:pt-[69px] 2xl:pb-[54px] 2xl:pt-[88px]">
      <h1 className="break-keep text-center text-2xl font-bold leading-[40px] text-default-violet">
        {t('main.provide_assistance')}
      </h1>
      <section className="my-[44px] flex justify-center lg:my-[48px] 2xl:my-[53px]">
        <ImageComponent path={reviewImg} alt="review image" />
      </section>
      <section className="space-y-[5px]">
        <p className="break-keep text-center text-base md:text-lg">{t('main.we_quickly_01')}</p>
        <p className="break-keep text-center text-base md:text-lg">{t('main.we_quickly_02')}</p>
      </section>
    </section>
  );
};

const Floatting = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const footerHeight = 135;

    // 문서 끝에서 135px 위에서 요소를 숨김
    if (scrollPosition + windowHeight >= documentHeight - footerHeight) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed bottom-0 z-10 flex h-[130px] w-full flex-col items-center justify-center bg-default-violet py-[20px] transition-opacity duration-500 ease-in-out md:h-[173px] lg:h-[93px] lg:flex-row lg:justify-between lg:px-[84px] xl:px-[180px] 2xl:px-[300px] ${isVisible ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'}`}
    >
      <div className="flex flex-col justify-center">
        <h2 className="text-bold text-center text-sm text-white md:text-left md:text-base">
          {t('main.subTitle')}
        </h2>
        <h1 className="text-center text-lg font-bold text-white md:text-xl lg:text-left">
          {t('main.title')}
        </h1>
      </div>
      <div className="mt-[11px] flex items-center md:mt-[19px] lg:mt-0">
        <Link
          to="/connect"
          className="flex h-[41px] w-[292px] items-center justify-center rounded-[10px] bg-white text-lg font-bold md:h-[57px] xl:w-[262px] 2xl:w-[350px]"
        >
          {t('main.connectBtn')}
        </Link>
      </div>
    </div>
  );
};
const MainSection1 = () => {
  return (
    <section className="relative w-full">
      <Banner />
    </section>
  );
};
const Main = () => {
  return (
    <div className="wrapper flex min-h-screen flex-col items-center">
      <div className="relative w-full 2xl:w-[1920px]">
        <MainSection1 />
        <MainSection2 />
        <MainSection3 />
        <MainSection4 />
      </div>
      <Floatting />
    </div>
  );
};

export default Main;
