import Footer from 'components/Footer';
import Header from 'components/Header';
import { PrivateRoute, PublicRoute } from 'components/ProtectedRoute';
import ScrollToTop from 'components/ScrollToTop';
import Main from 'pages/Main';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const SignUp = lazy(() => import('pages/user/SignUp'));
const Login = lazy(() => import('pages/Login'));
const SignUpRedirect = lazy(() => import('pages/user/SignUpRedirect'));
const Password = lazy(() => import('pages/Password'));
const ConnectInfo = lazy(() => import('pages/user/ConnectInfo'));
const ConnectMessage = lazy(() => import('pages/user/ConnectMessage'));
const ConnectComplete = lazy(() => import('pages/user/ConnectComplete'));
const UserManage = lazy(() => import('pages/admin/UserManage'));
const ConsultingManage = lazy(() => import('pages/admin/ConsultingManage'));
const AdminManage = lazy(() => import('pages/admin/AdminManage'));
const RegisterCounselor = lazy(() => import('pages/admin/RegisterCounselor'));
const UserInfo = lazy(() => import('pages/admin/UserInfo'));
const ConsultingInfo = lazy(() => import('pages/admin/ConsultingInfo'));
const MyPage = lazy(() => import('pages/user/MyPage'));

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Suspense>
        <Routes>
          {/* 메인 페이지: 모든 롤이 접근 가능 */}
          <Route path="/" element={<Main />} />

          {/* 어드민과 카운셀러만 접근 가능, 로그인 필요 */}
          <Route element={<PrivateRoute roles={['admin', 'counselor']} />}>
            <Route path="/admin" element={<UserManage />} />
            <Route path="/admin/user/:id" element={<UserInfo />} />
            <Route path="/admin/consulting" element={<ConsultingManage />} />
            <Route path="/admin/consulting/:id" element={<ConsultingInfo />} />
          </Route>

          {/* 어드민만 접근 가능, 로그인 필요 */}
          <Route element={<PrivateRoute roles={['admin']} />}>
            <Route path="/admin/register" element={<RegisterCounselor />} />
            <Route path="/admin/manage" element={<AdminManage />} />
          </Route>

          {/* 비로그인 상태에서만 접근 가능 */}
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup/complete" element={<SignUpRedirect />} />
            <Route path="/password" element={<Password />} />
          </Route>

          {/* 로그인 상태에서만 접근 가능 */}
          <Route element={<PrivateRoute roles={['user', 'admin', 'counselor']} />}>
            <Route path="/mypage/*" element={<MyPage />} />
            <Route path="/connect" element={<ConnectInfo />} />
            <Route path="/connect/message" element={<ConnectMessage />} />
            <Route path="/connect/complete" element={<ConnectComplete />} />
          </Route>
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
