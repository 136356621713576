import { useQuery } from '@tanstack/react-query';

const fetchLocalImage = path => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(path);
  });
};

const ImageComponent = ({ path, alt, style = '' }) => {
  const {
    data: imageUrl,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['image', path],
    queryFn: () => fetchLocalImage(path),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return <img src={imageUrl} alt={alt} className={style} loading="lazy" />;
};

export default ImageComponent;
