import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { useLanguage } from 'hooks/useLang';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LangObject = {
  ko: { nativeName: '한국어' },
  en: { nativeName: 'English' },
  // idn: { nativeName: 'Indonesian' },
  // ph: { nativeName: 'Filipino' },
  // vn: { nativeName: 'Vietnamese' },
  // lk: { nativeName: 'Sri Lankan' },
};

const initLang = getLanguage => {
  return getLanguage ? getLanguage : 'ko';
};

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const { getLanguage, setLanguage } = useLanguage();

  const [isToggleActive, setToggleActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const dropdownRef = useRef(null);

  const handleLangChage = lng => {
    setToggleActive(!isToggleActive);
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setLanguage(initLang(getLanguage));
  }, []);

  useEffect(() => {
    if (isToggleActive) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isToggleActive]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isToggleActive]);

  return (
    <div
      className="flex w-full items-center justify-start lg:w-auto lg:justify-end"
      ref={dropdownRef}
    >
      <div
        className="relative flex w-full justify-center text-[#333333] lg:py-0 lg:text-white"
        onClick={() => setToggleActive(!isToggleActive)}
      >
        <div className="w-full">
          <span className="mr-[36px] inline-block w-full cursor-pointer border-b border-solid border-bordergray py-[24px] pl-[30px] text-left font-bold transition-colors lg:border-0 2xl:text-lg">
            {LangObject[initLang(getLanguage)].nativeName}
          </span>
          <ul
            className={`relative left-0 z-10 transform rounded-md transition-all duration-300 ease-in-out lg:absolute lg:w-32 lg:border lg:border-bordergray lg:bg-white lg:shadow-lg 2xl:w-36 ${
              isToggleActive ? 'visible opacity-100' : 'invisible opacity-0'
            }`}
          >
            <li
              className="lg:boder-0 cursor-pointer rounded-t-[10px] border-b border-solid border-bordergray py-[12px] pl-[30px] text-left font-normal text-main__text__color hover:bg-[#EBEBEB] lg:px-4 lg:py-2 lg:text-center"
              onClick={() => handleLangChage(getLanguage)}
            >
              {LangObject[getLanguage].nativeName}
            </li>
            {Object.keys(LangObject).map(
              lng =>
                getLanguage !== lng && (
                  <li
                    key={lng}
                    className="lg:boder-0 cursor-pointer rounded-none border-b border-solid border-bordergray p-0 py-[12px] pl-[30px] text-left font-normal text-main__text__color hover:bg-[#EBEBEB] lg:border-t lg:border-solid lg:border-slate-200 lg:px-4 lg:py-2 lg:text-center lg:last:rounded-b-[10px]"
                    onClick={() => handleLangChage(lng)}
                  >
                    {LangObject[lng].nativeName}
                  </li>
                ),
            )}
          </ul>
        </div>
      </div>
      <span className="hidden lg:block">
        <MdArrowDropDown
          size="2rem"
          color="white"
          onClick={() => setToggleActive(!isToggleActive)}
        />
      </span>
    </div>
  );
};

export default React.memo(LanguageToggle);
