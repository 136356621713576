import { useQueryClient } from '@tanstack/react-query';
import { logEvent } from 'firebase/analytics';
import { useAuth } from 'hooks/useAuth';
import { useSetCollection } from 'hooks/useCollection';
import { useRole, useSetRole } from 'hooks/useRole';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCookie, removeCookie } from 'utils/cookies';
import hambuger from '../assets/images/hambuger.svg';
import { analytics, auth } from '../firebase';
import LanguageToggle from './LanguageToggle';

export const logout = async ({ queryClient, setRole, setCollection, navigate }) => {
  setRole(null);

  await Promise.all([removeCookie('role'), removeCookie('token')]);
  localStorage.removeItem('consultingState');

  setCollection(null);

  await auth.signOut();
  logEvent(analytics, 'web_auth_signout', {
    title: 'web_auth_signout',
  });

  queryClient.refetchQueries({ queryKey: ['currentUser'] });

  navigate('/');
};

const Navigation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const role = useRole(['role']);
  const setRole = useSetRole(['role']);
  const setCollection = useSetCollection(['collection']);
  const { user: AuthUser, isAuthenticated } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('token'));
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsBackgroundVisible(true);
      setTimeout(() => {
        setIsTransitioning(true);
      }, 500);
    } else {
      setIsTransitioning(false);
      setTimeout(() => {
        setIsBackgroundVisible(false);
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, [AuthUser]);

  const onLogOutClick = async () => {
    await logout({ queryClient, setRole, setCollection, navigate });
    setIsLoggedIn(false);
  };

  return (
    <nav className="relative flex items-center justify-between bg-default-violet p-5">
      <div className="flex items-center">
        <button className="block text-white lg:hidden" onClick={() => setIsOpen(!isOpen)}>
          <img src={hambuger} alt="navigation hambuger" />
        </button>
        <div className="hidden lg:ml-4 lg:flex lg:items-center lg:justify-center lg:text-base lg:font-bold">
          {!isLoggedIn ? (
            <>
              <h2
                onClick={() => navigate('/login')}
                className="w-28 cursor-pointer text-center align-middle text-white 2xl:w-36 2xl:text-lg"
              >
                {t('header.login')}
              </h2>
              <h2
                onClick={() => navigate('/signup')}
                className="w-28 cursor-pointer text-center align-middle text-white 2xl:w-36 2xl:text-lg"
              >
                {t('header.signup')}
              </h2>
            </>
          ) : (
            <>
              {role && role !== 'user' && (
                <div
                  onClick={() => navigate('/admin')}
                  className="w-28 flex-shrink-0 cursor-pointer text-center align-middle text-white 2xl:w-36 2xl:text-lg"
                >
                  {t('admin.title')}
                </div>
              )}
              {role && role === 'user' && (
                <div
                  onClick={() => navigate('/mypage')}
                  className="w-28 flex-shrink-0 cursor-pointer text-center align-middle text-white 2xl:w-36 2xl:text-lg"
                >
                  {t('mypage.title')}
                </div>
              )}
              <div
                onClick={() => onLogOutClick()}
                className="w-28 flex-shrink-0 cursor-pointer text-center align-middle text-white 2xl:w-36 2xl:text-lg"
              >
                {t('header.logout')}
              </div>
            </>
          )}
          <LanguageToggle />
        </div>
      </div>

      <div
        className={`fixed inset-0 -left-full z-20 flex justify-end transition-transform duration-500 ease-out ${
          isTransitioning ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div
          className={`absolute inset-0 bg-black transition-opacity duration-500 ${
            isBackgroundVisible ? 'bg-opacity-60' : 'bg-opacity-0'
          }`}
        ></div>
        <div className="relative h-screen bg-mobilNav-violet">
          <button
            onClick={() => setIsOpen(false)}
            className="absolute -left-10 top-4 w-[30px] text-white"
          >
            x
          </button>
          <div className="flex w-[300px] flex-col">
            <h1 className="border-b border-solid border-bordergray py-[26px] pl-[30px] text-xl font-bold text-default-violet">
              Help Me
            </h1>
            {!isLoggedIn ? (
              <>
                <h2
                  onClick={() => {
                    navigate('/login');
                    setIsOpen(false);
                  }}
                  className="cursor-pointer border-b border-solid border-bordergray py-[24px] pl-[30px] text-left align-middle font-bold"
                >
                  {t('header.login')}
                </h2>
                <h2
                  onClick={() => {
                    navigate('/signup');
                    setIsOpen(false);
                  }}
                  className="cursor-pointer border-b border-solid border-bordergray py-[24px] pl-[30px] text-left align-middle font-bold "
                >
                  {t('header.signup')}
                </h2>
              </>
            ) : (
              <>
                {role && role !== 'user' && (
                  <div
                    onClick={() => {
                      navigate('/admin');
                      setIsOpen(false);
                    }}
                    className="cursor-pointer border-b border-solid border-bordergray py-[24px] pl-[30px] text-left align-middle font-bold "
                  >
                    {t('admin.title')}
                  </div>
                )}
                {role && role === 'user' && (
                  <div
                    onClick={() => {
                      navigate('/mypage');
                      setIsOpen(false);
                    }}
                    className="cursor-pointer border-b border-solid border-bordergray py-[24px] pl-[30px] text-left font-bold "
                  >
                    {t('mypage.title')}
                  </div>
                )}
                <div
                  onClick={() => {
                    onLogOutClick();
                    setIsOpen(false);
                  }}
                  className="cursor-pointer border-b border-solid border-bordergray py-[20px] pl-[30px] text-left align-middle font-bold "
                >
                  {t('header.logout')}
                </div>
              </>
            )}
            <LanguageToggle />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
