import { useQuery } from '@tanstack/react-query';
import { logEvent } from 'firebase/analytics';
import { onAuthStateChanged } from 'firebase/auth';
import { getCookie } from 'utils/cookies';
import { analytics, auth } from '../firebase';

const fetchUser = () => {
  logEvent(analytics, 'web_auth_state_changed', {
    title: 'auth_state_changed',
  });
  return new Promise(resolve => {
    onAuthStateChanged(auth, user => {
      resolve(user);
    });
  });
};

export const useAuth = () => {
  const { data: user, isLoading } = useQuery({
    queryKey: ['currentUser'],
    queryFn: fetchUser,
    enabled: !!getCookie('token'),
  });

  return {
    user,
    isLoading,
    isAuthenticated: !!getCookie('token'),
  };
};
