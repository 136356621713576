import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../assets/images/icon/Arrow.svg';

// 이미지 import
import mainBanner1024_760Webp from '../assets/images/banner/main_banner_1024_760.webp';
import mainBanner1440_760Webp from '../assets/images/banner/main_banner_1440_760.webp';
import mainBanner1920_760Webp from '../assets/images/banner/main_banner_1920_760.webp';
import mainBanner480_710Webp from '../assets/images/banner/main_banner_480_710.webp';
import mainBanner640_680Webp from '../assets/images/banner/main_banner_640_680.webp';

import mainBanner1024_760Jpg from '../assets/images/banner/main_banner_1024_760.jpg';
import mainBanner1440_760Jpg from '../assets/images/banner/main_banner_1440_760.jpg';
import mainBanner1920_760Jpg from '../assets/images/banner/main_banner_1920_760.jpg';
import mainBanner480_710Jpg from '../assets/images/banner/main_banner_480_710.jpg';
import mainBanner640_680Jpg from '../assets/images/banner/main_banner_640_680.jpg';

import mainBanner2_1024_760Webp from '../assets/images/banner/main_banner2_1024_760.webp';
import mainBanner2_1440_760Webp from '../assets/images/banner/main_banner2_1440_760.webp';
import mainBanner2_1920_760Webp from '../assets/images/banner/main_banner2_1920_760.webp';
import mainBanner2_480_710Webp from '../assets/images/banner/main_banner2_480_710.webp';
import mainBanner2_640_680Webp from '../assets/images/banner/main_banner2_640_680.webp';

import mainBanner2_1024_760Jpg from '../assets/images/banner/main_banner2_1024_760.jpg';
import mainBanner2_1440_760Jpg from '../assets/images/banner/main_banner2_1440_760.jpg';
import mainBanner2_1920_760Jpg from '../assets/images/banner/main_banner2_1920_760.jpg';
import mainBanner2_480_710Jpg from '../assets/images/banner/main_banner2_480_710.jpg';
import mainBanner2_640_680Jpg from '../assets/images/banner/main_banner2_640_680.jpg';

const mockImages = [
  {
    src2xWebp: {
      '2xl': mainBanner1920_760Webp,
      xl: mainBanner1440_760Webp,
      lg: mainBanner1024_760Webp,
      md: mainBanner640_680Webp,
      sm: mainBanner480_710Webp,
    },
    src2xJpg: {
      '2xl': mainBanner1920_760Jpg,
      xl: mainBanner1440_760Jpg,
      lg: mainBanner1024_760Jpg,
      md: mainBanner640_680Jpg,
      sm: mainBanner480_710Jpg,
    },
    srcJpg: {
      '2xl': mainBanner1920_760Jpg,
    },
  },
  {
    src2xWebp: {
      '2xl': mainBanner2_1920_760Webp,
      xl: mainBanner2_1440_760Webp,
      lg: mainBanner2_1024_760Webp,
      md: mainBanner2_640_680Webp,
      sm: mainBanner2_480_710Webp,
    },
    src2xJpg: {
      '2xl': mainBanner2_1920_760Jpg,
      xl: mainBanner2_1440_760Jpg,
      lg: mainBanner2_1024_760Jpg,
      md: mainBanner2_640_680Jpg,
      sm: mainBanner2_480_710Jpg,
    },
    srcJpg: {
      '2xl': mainBanner2_1920_760Jpg,
    },
  },
];

const Banner = () => {
  const slideRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [key, setKey] = useState(0);

  const handlePrevClick = () => {
    const newSlideIndex = currentSlide === 0 ? mockImages.length - 1 : currentSlide - 1;
    setCurrentSlide(newSlideIndex);
  };

  const handleNextClick = () => {
    const newSlideIndex = currentSlide === mockImages.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(newSlideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 3500);

    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    const handleResize = () => {
      setKey(prevKey => prevKey + 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateBannerHeight = () => {
      if (slideRef.current) {
        const imgElement = slideRef.current.querySelector('img');
        if (imgElement) {
          setBannerHeight(imgElement.clientHeight);
        }
      }
    };
    updateBannerHeight();
    window.addEventListener('resize', updateBannerHeight);
    () => {
      window.removeEventListener('resize', updateBannerHeight);
    };
  }, []);

  return (
    <div className="relative lg:min-h-[760px]">
      {mockImages.map((image, index) => (
        <div
          key={`${index}`}
          className={`transition-opacity duration-1000 ease-in-out ${
            currentSlide === index ? 'opacity-100' : 'opacity-0'
          } ${index === 0 ? 'static' : 'absolute left-0 top-0 h-full w-full'}`}
        >
          <picture key={`${key}-${index}`}>
            <source srcSet={image.src2xWebp['sm']} type="image/webp" media="(max-width: 480px)" />
            <source srcSet={image.src2xWebp['md']} type="image/webp" media="(max-width: 640px)" />
            <source srcSet={image.src2xWebp['lg']} type="image/webp" media="(max-width: 1024px)" />
            <source srcSet={image.src2xWebp['xl']} type="image/webp" media="(max-width: 1440px)" />
            <source srcSet={image.src2xWebp['2xl']} type="image/webp" media="(min-width: 1441px)" />
            <source srcSet={image.src2xJpg['sm']} type="image/jpeg" media="(max-width: 480px)" />
            <source srcSet={image.src2xJpg['md']} type="image/jpeg" media="(max-width: 640px)" />
            <source srcSet={image.src2xJpg['lg']} type="image/jpeg" media="(max-width: 1024px)" />
            <source srcSet={image.src2xJpg['xl']} type="image/jpeg" media="(max-width: 1440px)" />
            <img
              src={image.srcJpg['2xl']}
              alt={`Slide ${index}`}
              className="max-h-[710px] w-full md:max-h-[680px] lg:max-h-[760px]"
            />
          </picture>
        </div>
      ))}

      <div className="flex w-full justify-center">
        <ul className="absolute bottom-7 flex h-[25px] w-[53px] items-center justify-center gap-[7px] rounded-[20px] bg-[#D9D9D9] p-1 md:h-[29px] md:w-[63px]">
          {mockImages.map((_, idx) => (
            <li
              key={idx}
              className={`h:w-[17px] h-[15px] w-[15px] rounded-full md:w-[17px] ${
                idx === currentSlide ? 'bg-[#939393]' : 'bg-[#F6F6F6]'
              }`}
              onClick={() => setCurrentSlide(idx)}
            />
          ))}
        </ul>
      </div>
      <button onClick={handlePrevClick} className="absolute left-[16px] top-1/2 md:left-[73px]">
        <Arrow aria-label="Prev Button" className=" w-[19px] md:w-auto" />
      </button>
      <button
        onClick={handleNextClick}
        className="absolute right-[16px] top-1/2 rotate-180 md:right-[73px]"
      >
        <Arrow aria-label="Next Button" className=" w-[19px] md:w-auto" />
      </button>
    </div>
  );
};

export default Banner;
