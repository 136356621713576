import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useLanguage = () => {
  const queryClient = useQueryClient();

  const { data: getLanguage } = useQuery({
    queryKey: ['lang'],
    queryFn: () => localStorage.getItem('lang'),
    initialData: () => localStorage.getItem('lang'),
  });

  const setLanguage = newLanguage => {
    localStorage.setItem('lang', newLanguage);
    queryClient.setQueryData(['lang'], newLanguage);
  };

  return { getLanguage, setLanguage };
};
