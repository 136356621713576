import i18n from 'i18next';
import LanguaeDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './translation.en.json';
import translationKo from './translation.ko.json';

export const saveLanguageToStorage = language => {
  localStorage.setItem('lang', language);
};

const getLanguageFromStorage = () => {
  return localStorage.getItem('lang');
};

const initialLanguage = getLanguageFromStorage() || 'en';

i18n
  .use(LanguaeDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    debug: false,
    lng: initialLanguage,
    fallbackLng: 'ko',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      caches: ['cookie', 'localStorage'],
    },
    resources: {
      en: {
        translation: translationEn,
      },
      ko: {
        translation: translationKo,
      },
    },
  });

saveLanguageToStorage(initialLanguage);

export default i18n;
